import React, { useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";

const ServiceRequestTable = ({ serviceReq_Data }) => {
  const [modal, setModal] = useState(false);
  const [location_modal, setLocation_modal] = useState(false);
  const [secondmodal, setSecondodal] = useState(false);
  const [thirdModal, setThirdModal] = useState(false);
  const [offer_modal, setOffer_modal] = useState(false);
  const [getOffer, setGetOffer] = useState();
  const [getByIdData, setGetByIdData] = useState([]);
  const [payMentStatus, setPayMentStatus] = useState();
  const [payMentDetails, setPayMentDetails] = useState([]);
  const [finance_beakup, setFinance_breakup] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState([]);

  const toggleDescription = (idx) => {
    setExpandedDescriptions((prevExpanded) => {
      const newState = [...prevExpanded];
      newState[idx] = !newState[idx];
      return newState;
    });
  };
  const truncateDescription = (description) => {
    const words = description.split(" ");
    if (words.length > 4) {
      return words.slice(0, 4).join(" ") + "...";
    }
    return description;
  };
  const toggle = () => setModal(!modal);
  const toggletwo = () => setSecondodal(!secondmodal);
  const togglethree = () => setThirdModal(!thirdModal);
  const togglefur = () => setLocation_modal(!location_modal);
  const togglefive = () => setOffer_modal(!offer_modal);

  const token = localStorage.getItem("authToken");
  const api_url = process.env.REACT_APP_BASE_URL;
  // const api_url = "http://localhost:3000";

  const getById = async (id, type) => {
    if (type === "img") {
      toggle();
    } else if (type === "detail") {
      toggletwo();
    } else if (type === "finance") {
      togglethree();
    } else if (type === "map") {
      togglefur();
    }
    try {
      const response = await fetch(
        `${api_url}/servicerequests/getBy_Id/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const result = await response.json();
        const maindata = result.data;
        setGetByIdData(maindata);
        const paymentStatus = maindata.payment_status;
        setPayMentStatus(paymentStatus);
        setFinance_breakup(maindata.financial_breakup);
        setPayMentDetails(maindata.payment_details);
      } else {
        console.log("server error");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const get_offer = async (of_id) => {
    togglefive();
    try {
      const response = await fetch(
        `${api_url}/serviceoffers/getBy_Id/${of_id}`,
        {
          method: "GET",
        }
      );
      if (response.status === 200) {
        const result = await response.json();
        const allData = result.data;
        setGetOffer(allData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const timestamp = payMentDetails?.created_at;
  const date = new Date(timestamp * 1000);
  const readableDate = date.toLocaleDateString();
  return (
    <>
      {serviceReq_Data.map((data, idx) => (
        <tr key={idx} className={data.payment_status === "Paid" ? `service_table add_aNewBG_color` : ` service_table`}>
          <td>{idx + 1}</td>
          <td>
            {data.customers[0]
              ? `${data.customers[0]?.first_name} ${data.customers[0]?.last_name}`
              : "N/A"}
          </td>
          <td>{data.customers[0]?.mobile ?? "N/A"}</td>
          <td>{data.customers[0]?.email ?? "N/A"}</td>
          <td>
            <span className="text_bold" data-tooltip-id="view_offer" onClick={() => get_offer(data._id)} >
              {data.title}
            </span>
            <ReactTooltip id="view_offer" place="top" variant="dark" content="Click to View Offers!" />
          </td>
          <td>
            {expandedDescriptions[idx]
              ? data.description
              : truncateDescription(data.description)}
            {!expandedDescriptions[idx] &&
              data.description.split(" ").length > 4 && (
                <b className="expandtxt text-info cursor-pointer" onClick={() => toggleDescription(idx)} >   Read More  </b>
              )}
            {expandedDescriptions[idx] && (
              <b className="expandtxt text-warning cursor-pointer" onClick={() => toggleDescription(idx)}  >  Read Less </b>
            )}
          </td>
          <td>{data.budget}</td>
          <td>
            <div className="map_icon_here">
              {data.location?.address ? (
                <>
                  <img data-tooltip-id="view_map" onClick={() => getById(data._id, "map")} src="/assets/images/map.png" alt="" />
                  <ReactTooltip id="view_map" place="left" variant="success" content="Click to View Location!" />
                </>
              ) : (
                <>
                  <img rel="noreferrer" data-tooltip-id="no_map" className="no_map" src="/assets/images/nomap.png" alt="" />
                  <ReactTooltip id="no_map" place="left" variant="info" content="Location not available!" />
                </>
              )}
            </div>
          </td>
          <td className="text-center">
            {data.images.length ? (
              <>
                <div className="img_section">
                  <div className="one_img_here service_req_img">
                    <img src={data.images[0]} alt="" />
                    <div className="overLay_img">
                      <div className="overLy_text">+ {data.images.length}</div>
                    </div>
                  </div>
                </div>
                <span className="View_imgCss" onClick={() => getById(data._id, "img")}>
                  {data.images.length > 1 ? `View Images` : `View Image`}
                </span>
              </>
            ) : (
              <>
                <div className="one_img_here">
                  <img src="/assets/images/noImg.png" alt="" />
                </div>
                <span className="text-secondary View_imgCss">No Image</span>
              </>
            )}
          </td>
          <td className="text-center">
            {data.status === "Open" ? (
              <span className="font-size-11 badge-soft-primary badge text-primary">
                {data.status}
              </span>
            ) : data.status === "Assigned" ? (
              <span className="font-size-11 badge-soft-warning badge">
                {data.status}
              </span>
            ) : data.status === "Expired" ? (
              <span className="font-size-11 badge-soft-secondary badge">
                {data.status}
              </span>
            ) : data.status === "Completed" ? (
              <span className="font-size-11 badge-soft-success badge">
                {data.status}
              </span>
            ) : (
              ""
            )}
          </td>
          <td className="text-center">
            {data.payment_status === "Pending" ? (
              <span className="font-size-11 badge-soft-warning badge">  {data.payment_status} </span>
            ) : data.payment_status === "Paid" ? (
              <>
                <span className="font-size-11 badge bg-success rounded-pill ">  {data.payment_status}</span>
                &emsp;
                {data.payment_status === "Paid" ? (
                  <button className="btn btn-outline-primary" onClick={() => getById(data._id, "detail")} >
                    <i className="bx bx-money font-size-16 align-middle me-2"></i> View
                  </button>
                ) : (
                  <button disabled className="btn btn-outline-secondary">  <i className="bx bx-money font-size-16 align-middle me-2"></i>
                    View
                  </button>
                )}
              </>
            ) : (
              ""
            )}
          </td>
          <td className="text-center">
            {data.payout_status === "Pending" ? (
              <span className="font-size-11 badge-soft-warning badge"> {data.payout_status} </span>
            ) : data.payout_status === "Released" ? (
              <span className="font-size-11 badge-soft-success badge"> {data.payout_status} </span>
            ) : (
              ""
            )}
          </td>
          <td className="text-center">
            {data.refund_status !== "Released" ? (<span className="font-size-11 badge-soft-secondary badge">  None </span>
            ) : data.refund_status === "Released" ? (
              <span className="font-size-11 badge-soft-suceess badge">  {data.refund_status} </span>
            ) : (
              ""
            )}
          </td>
          <td>
            {data.payment_status === "Paid" ? (
              <button className="btn btn-outline-primary" onClick={() => getById(data._id, "finance")}  >
                <i className="bx bx-dollar-circle font-size-16 align-middle me-2"></i> View
              </button>
            ) : (
              <button disabled className="btn btn-outline-secondary"> <i className="bx bx-dollar-circle font-size-16 align-middle me-2"></i>
                View
              </button>
            )}
          </td>
        </tr>
      ))}

      {/* _____________Modals___________________________ */}
      <Modal isOpen={modal} size="md" toggle={toggle}>
        <ModalHeader toggle={toggle}> View All Images Here</ModalHeader>
        <ModalBody>
          <div className="upadteform">
            <div className="row">
              {getByIdData.images?.length > 0 ? (
                getByIdData.images.map((item, i) => (
                  <div className="col-md-4 col-sm-4 col-6 servi_imgCrad" key={i}  >
                    <div className="service_request_img_here">  <img src={item} alt="" /> </div>
                  </div>
                ))
              ) : (
                <div className="col-md-4 col-4">
                  <div className="service_request_img_here">  <img src="/assets/images/noImg.png" alt="" />  </div>
                </div>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={secondmodal} size="lg" toggle={toggletwo}>
        <ModalHeader toggle={toggletwo}> Payment Details </ModalHeader>
        <ModalBody>
          <div className="upadteform">
            <div className="row">
              {payMentStatus === "Paid" ? (
                payMentDetails ? (
                  <>
                    <p> <span style={{ fontWeight: "600" }}>Date: </span> {readableDate}  </p>
                    <table className="table table-bordered table-responsive">
                      <tbody>
                        <tr>
                          <th>Payment Id</th>
                          <td>{payMentDetails.id}</td>
                          <th>Amount</th>
                          <td>{payMentDetails.amount / 100}</td>
                        </tr>
                        <tr>
                          <th>Currency</th>
                          <td>{payMentDetails.currency}</td>
                          <th>Status</th>
                          <td>{payMentDetails.status}</td>
                        </tr>
                        <tr>
                          <th>Order Id</th>
                          <td>{payMentDetails.order_id}</td>
                          <th>Invoice Id</th>
                          <td>
                            {payMentDetails.invoice_id === null
                              ? "----"
                              : payMentDetails.invoice_id}
                          </td>
                        </tr>
                        <tr>
                          <th>Method</th>
                          <td>{payMentDetails.method}</td>
                          <th>Amount Refunded</th>
                          <td>{payMentDetails.amount_refunded}</td>
                        </tr>
                        <tr>
                          <th>Refund Status</th>
                          <td>
                            {payMentDetails.amount_refunded === 0
                              ? "----"
                              : payMentDetails.refund_status}
                          </td>
                          <th>Captured</th>
                          <td>
                            {payMentDetails.captured === true ? " Yes" : ""}
                          </td>
                        </tr>
                        <tr>
                          <th>Description</th>
                          <td>{payMentDetails.description}</td>
                          <th>Email</th>
                          <td>{payMentDetails.email}</td>
                        </tr>
                        <tr>
                          <th>Mobile Number</th>
                          <td>{payMentDetails.contact}</td>
                          <th>Fee</th>
                          <td>{payMentDetails.fee}</td>
                        </tr>
                        <br />
                        <tr>
                          <h4 className="text-dark"> Card Details</h4>
                        </tr>
                        <br />
                        <tr>
                          <th>Card Id</th>
                          <td>{payMentDetails.card.id}</td>
                          <th>Entity</th>
                          <td>{payMentDetails.card.entity}</td>
                        </tr>
                        <tr>
                          <th>Name</th>
                          <td>{payMentDetails.card.name}</td>
                          <th>Card Last 4 Digit</th>
                          <td>{payMentDetails.card.last4}</td>
                        </tr>
                        <tr>
                          <th>Network</th>
                          <td>{payMentDetails.card.network}</td>
                          <th>Card Type</th>
                          <td>{payMentDetails.card.type}</td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                ) : (
                  " "
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={thirdModal} size="lg" toggle={togglethree}>
        <ModalHeader toggle={togglethree}> Financial Breakup</ModalHeader>
        <ModalBody>
          <div className="upadteform">
            {payMentStatus === "Paid" ? (
              finance_beakup ? (
                <table className="table table-bordered table-responsive">
                  <tbody>
                    <tr>
                      <th>Agreed Service Amount </th>
                      <td>{finance_beakup.agreed_service_amount}</td>
                      <th>Seeker Platform Fee</th>
                      <td>{finance_beakup.seeker_platform_fee}</td>
                    </tr>
                    <tr>
                      <th>Deductable Amount</th>
                      <td>{finance_beakup.deductable_amount}</td>
                      <th>Provider Platform Fee</th>
                      <td>{finance_beakup.provider_platform_fee}</td>
                    </tr>
                    <tr>
                      <th>Payable Amount</th>
                      <td>{finance_beakup.payable_amount}</td>
                      <th>Convenience Fee</th>
                      <td>{finance_beakup.convenience_fee}</td>
                    </tr>
                    <tr>
                      <th> Refunded Amount</th>
                      <td>{finance_beakup.refunded_amount}</td>
                      <th>Convenience Fee GST</th>
                      <td>{finance_beakup.convenience_fee_gst}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                "hiii"
              )
            ) : (
              ""
            )}
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={location_modal} size="md" toggle={togglefur}>
        <ModalHeader toggle={togglefur}>View Location</ModalHeader>
        <ModalBody>
          <div className="upadteform">
            <Row>
              <Col>
                {getByIdData.location?.address ? (
                  <>
                    <h5>{getByIdData.location?.address}</h5>
                    <p>
                      <b>Coordinates</b> {getByIdData.location?.coordinates}
                    </p>
                    <div className="view_inMap"></div>
                  </>
                ) : (
                  <h5>Location Not Available</h5>
                )}
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={offer_modal} size="lg" toggle={togglefive}>
        <ModalHeader toggle={togglefive}>
          <p>Total Offers: {getOffer?.length}</p>
        </ModalHeader>
        <ModalBody>
          <div className="upadteform">
            <Row>
              <Col>
                <table className="table table-bordere view_providerTable rounded table-responsive">
                  <thead>
                    <tr>
                      <th>S.N</th>
                      <th>Provider</th>
                      <th>Provider Mobile </th>
                      <th>Offer Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getOffer && getOffer?.length > 0 ? (
                      getOffer.map((offer, id) => {
                        const status = offer.status;
                        return (
                          <>
                            <tr
                              className={
                                status === "Accepted"
                                  ? `table_row_bg_success `
                                  : "bg-light"
                              }
                            >
                              <td>{id + 1}</td>
                              <td>
                                {offer?.providers[0].first_name}
                                {offer?.providers[0].last_name}
                              </td>
                              <td>{offer?.providers[0].mobile}</td>
                              <td> ₹ {offer.offer_amount}</td>
                              <td>
                                {offer.status === "Pending" ? (
                                  <span className="font-size-11 badge bg-warning rounded-pill ">
                                    {offer.status}
                                  </span>
                                ) : (
                                  <span className="font-size-11 badge bg-success rounded-pill ">
                                    {offer.status}
                                  </span>
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="15" className="react-bs-table-no-data">
                          Not Available any offer!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ServiceRequestTable;
