import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Swal from "sweetalert2";
import Select from 'react-select';

const BannerTable = ({ alldata }) => {
  const [modal, setModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [updated_bn_img, setUpdated_bn_img] = useState();
  const [show_btn, setShow_btn] = useState(false);
  const [getById_Data, setGetById_Data] = useState();
  const [allProviders, setAllProviders] = useState();
  const [provider_id, setProvider_id] = useState();

  const toggle = () => setModal(!modal);
  const toggletwo = () => setSecondModal(!secondModal);

  const token = localStorage.getItem("authToken");
  const api_url = process.env.REACT_APP_BASE_URL;
  // const api_url = 'http://localhost:3000';

  const [data, setData] = useState();
  useEffect(() => {
    setData(alldata);
    // fetch_providers();
  });
  useEffect(() => {
    fetch_providers();
  }, []);
  const fetch_providers = async () => {
    try {
      const response = await fetch(`${api_url}/banners/getall_providers`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const results = await response.json();
      const dataHere = results.data;
      setAllProviders(dataHere);
    } catch (err) {
      console.log(err, 'customers fetching error')
    }
  };
  const updateBanner = async (id, type) => {
    if (type === "title") {
      toggle();
    } else if (type === "img") {
      toggletwo();
    }
    try {
      const response = await fetch(`${api_url}/banners/getBy_id/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const result = await response.json();
        setGetById_Data(result.data);
      } else {
        console.log("server error");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onChangeTitle = (e) => {
    const { name, value } = e.target;
    setGetById_Data((pre) => ({ ...pre, [name]: value }));
    setShow_btn(true);
  };
  const providerOnchange = (selectedOption) => {
    setProvider_id(selectedOption.value);
    setShow_btn(true);
  }
  const handle_titleUpdate = async (id) => {
    try {
      const payload = { id: id, title: getById_Data.title, provider_id };
      const response_two = await fetch(`${api_url}/banners/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (response_two.status === 200) {
        const updatedData = await response_two.json();
        Swal.fire({
          title: "success!",
          text: " Title Update Successfully!",
          icon: "success",
        });
        // setData((pre) =>
        //   pre.map((items) =>
        //     items._id === updatedData._id ? { ...items, ...updatedData } : items
        //   )
        // );
        setShow_btn(false);
        setModal(false);
      } else {
        Swal.fire({
          title: "Error!",
          text: " Something went wrong!",
          icon: "error",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onChange_imghandle = (e) => {
    setUpdated_bn_img(e.target.files[0]);
    setShow_btn(true);
  };
  const handlemg_Updt = async (Bn_id) => {
    try {
      const formData = new FormData();
      formData.append("id", Bn_id);
      formData.append("document_file", updated_bn_img);
      const response = await fetch(`${api_url}/banners/upload_document`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (response.status === 200) {
        Swal.fire({
          title: "success!",
          text: " Banner Update Successfully!",
          icon: "success",
        });
        setSecondModal(false);
      } else {
        Swal.fire({
          title: "Error!",
          text: " Something went wrong!",
          icon: "error",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const dlt_banner = async (bnid) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
    });
    if (result.isConfirmed) {
      try {
        const response = await fetch(`${api_url}/banners/dlt_banner/${bnid}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const newdata = await response.json();
        if (response.ok) {
          Swal.fire({
            title: "Deleted!",
            text: newdata.message,
            icon: "success",
          });

          const updatedData = data.filter((item) => item._id !== bnid);
          setData(updatedData);
        } else {
          Swal.fire({
            title: "Error!",
            text: "Something went wrong",
            icon: "error",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
        });
      }
    }
  };

  return (
    <>
      {data && data.length > 0 ? (
        data.map((data, idx) => (
          <tr key={idx}>
            <td>{idx + 1}</td>
            <td>
              {data.title} &nbsp;
              <Link
                to={""}
                className="badge-soft-success badge  text-success p-1"
                onClick={() => updateBanner(data._id, "title")}
              >
                <i className="bx bxs-edit"></i>
              </Link>
            </td>
            <td>
              <div className="Banner_img_here">
                <img
                  src={data.image_url}
                  onClick={() => updateBanner(data._id, "img")}
                  alt=""
                />
              </div>
            </td>
            <td>

              &emsp;
              <Link
                to={""}
                onClick={() => dlt_banner(data._id)}
                className="badge-soft-danger badge text-danger p-1"
              >
                <i className="bx bxs-trash"></i>
              </Link>
              &emsp;
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="15" className="react-bs-table-no-data">
            No data to display.
          </td>
        </tr>
      )}
      {/* ------update modal------- */}
      <Modal isOpen={modal} size="md" toggle={toggle}>
        <ModalHeader toggle={toggle}> Update Title</ModalHeader>
        <ModalBody className="updateBanner_modal">
          <div className="upadteform ">
            {getById_Data && (
              <form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <label htmlFor="title" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={onChangeTitle}
                        value={getById_Data.title}
                        name="title"
                        id="title"
                        placeholder="Enter Title"
                      />
                    </div>

                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label htmlFor="provider_id" className="form-label">  Select Providers  </label>
                      <Select
                        name="provider_id"
                        id="provider_id"
                        options={allProviders?.map(prd => ({
                          value: prd._id,
                          label: prd.first_name + ' ' + prd.last_name
                        }))}
                        onChange={providerOnchange}
                        placeholder="Select Providers"
                        isSearchable={true}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="mb-3 text-center buTTon_center mt-4">
                  {show_btn == true ? (
                    <button
                      type="submit"
                      className="btn btn-info"
                      onClick={() => handle_titleUpdate(getById_Data._id)}
                    >
                      Update <i className="bx bx-edit-alt"></i>
                    </button>
                  ) : (
                    <button type="submit" disabled className="btn btn-info">
                      Update <i className="bx bx-edit-alt"></i>
                    </button>
                  )}
                </div>
              </form>
            )}
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={secondModal} size="md" toggle={toggletwo}>
        <ModalHeader toggle={toggletwo}> Update Image</ModalHeader>
        <ModalBody>
          <div className="upadteform modal_banner_img">
            {getById_Data && (
              <form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col md={12} className="text-center mb-4">
                    <div className="Banner_img_here ">
                      <img src={getById_Data.image_url} alt="" />
                    </div>
                  </Col>
                  <Col md={2} sm={2}></Col>
                  <Col md={8} sm={8}>
                    <div className="mb-3">
                      <input
                        type="file"
                        className="form-control"
                        name="document_file"
                        onChange={onChange_imghandle}
                        id="document_file"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <div className="mb-3 text-center buTTon_center mt-4">
                  {show_btn == true ? (
                    <button
                      type="submit"
                      className="btn btn-info"
                      onClick={() => handlemg_Updt(getById_Data._id)}
                    >
                      Update <i className="bx bx-edit-alt"></i>
                    </button>
                  ) : (
                    <button type="submit" disabled className="btn btn-info">
                      Update <i className="bx bx-edit-alt"></i>
                    </button>
                  )}
                </div>
              </form>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default BannerTable;
