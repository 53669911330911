import React, { useState, useEffect } from 'react'
import Breadcrumb from '../Common/Breadcrumb'
import { Card, Row, Col, CardBody, Container, Table } from "reactstrap";
import Advertising_comTable from '../Components/DataTables/Advertising_comTable';

const Advertising_competition = () => {
    const [all_Data, setAll_Data] = useState([]);
    const [query, setQuery] = useState('');
    const [selectedStatus, setSelectedStatus] = useState();
    const [filteredData, setFilteredData] = useState([]);
    const [results, setResults] = useState([]);
    const token = localStorage.getItem('authToken');
    const api_url = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        document.title = "WorkDone App | Advertising Competition";

        fetch_allData();
    }, []);
    const fetch_allData = async () => {
        try {
            const response = await fetch(`${api_url}/interestdata/getall`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const results = await response.json();
            const dataHere = results.data;
            setAll_Data([...dataHere].reverse());
        } catch (err) {
            console.log(err, 'customers fetching error')
        }
    };
    const handleSelect = (e) => {
        const value = e.target.value;
        if (value === 'All') {
            setFilteredData(all_Data);
        } else {
            const filtered = all_Data.filter(item => item.payment_status === value);
            setFilteredData(filtered);
        }
    }
    const handlesearch = (e) => {
        const inputValue = e.target.value;
        setQuery(inputValue);
        const filteredResults = all_Data.filter(item =>
            item.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
            item.email?.toLowerCase().includes(inputValue.toLowerCase()) ||
            item.phone?.toLowerCase().includes(inputValue.toLowerCase())
        );
        setResults(filteredResults);
    };
    return (
        <>
            <Breadcrumb title="Dashboard" breadcrumbItem="Advertising Competition" />
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Row className="mt-2">
                                <Col md={8}>
                                    <h5>View Here All Data!</h5>
                                    <div className="mt-3">
                                        <p className='text_bold'>Showing All Entries: <span className='text-primary'> {all_Data.length}</span></p>
                                    </div>
                                </Col>
                                <Col md={4} className="text-end">
                                    <div className="searching_optipn text-end">
                                        <Row>
                                            <Col md={4} className="mt-1 text-left">
                                                <label htmlFor="searchiong">Search:</label>
                                            </Col>
                                            <Col md={8}>
                                                <input type="text" value={query} onChange={handlesearch} placeholder="Search Here" className="form-control" />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <div className="table-responsive ">
                                <Table className="table align-middle table-nowrap scrollable_table  w-100 mb-0 table-bordered table-hover  usersTables">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="align-middle">S.N</th>
                                            <th className="align-middle">Team Name</th>
                                            <th className="align-middle">Team Phone</th>
                                            <th className="align-middle">Team Email</th>
                                            {/* <th className="align-middle"> Participants</th> */}
                                            <th className="align-middle text-center">
                                                <span>
                                                    <select name="payment_status" value={selectedStatus} onChange={handleSelect} className='table_select_Css'>
                                                        <option selected disabled>Payment Status</option>
                                                        <option value="Paid">Paid</option>
                                                        <option value="Pending">Pending</option>
                                                        <option value="All">All Data</option>
                                                    </select>
                                                </span>
                                            </th>
                                            <th className="align-middle">View Details </th>
                                            <th className="align-middle">View Document </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {query ?
                                            <Advertising_comTable userData={results} />
                                            : filteredData.length > 0 ?
                                                <Advertising_comTable userData={filteredData} />
                                                : <Advertising_comTable userData={all_Data} />}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Advertising_competition
