import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import Swal from "sweetalert2";
import Lottie from "lottie-react";
import login from "../Components/Anim_files/login.json";

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    pwd: "",
  });

  const handleonchange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handlelogin = async (e, v) => {
    e.preventDefault();
    // navigate('/Dashboard');
    const payload = {
      username: formData.username,
      pwd: formData.pwd,
    };
    // console.log(payload);
    try {
      const api_url = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${api_url}/adminusers/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const results = await response.json();
      if (results.status === 1) {
        const token = results.data.token;
        localStorage.setItem("authToken", token);
        localStorage.setItem("name", payload.username);
        navigate("/Dashboard");
      } else {
        Swal.fire({
          title: "Invalid credentials!",
          text: results.message,
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  return (
    <>
      <div className="account-pages ">
        <div className="background_layer pt-sm-5 ">
          <Container className="pt-5">
            <Row className=" justify-content-center">
              <Col md={10} lg={10} xl={10}>
                <Card className=" overflow-hidden  loginCard">
                  <CardBody className="pt-4">
                    <Row>
                      <Col lg={5} md={5} sm={5}>
                        <Lottie animationData={login} />
                      </Col>
                      <Col lg={7} md={7} sm={7}>
                        <Row>
                          <Col md={6} sm={6}>
                            <div className="bg-transparent">
                              <div className="text-light text-left ">
                                <h5 className=" h1" style={{ color: "#fff" }}>
                                  Login
                                </h5>
                              </div>
                            </div>
                          </Col>
                          <Col md={6} sm={6}>
                            <div className="login_logo_img text-end">
                              <img src="/assets/images/logo.png" alt="" />
                            </div>
                          </Col>
                        </Row>

                        <div className="p-2">
                          <form
                            onSubmit={handlelogin}
                            method="post"
                            className="form-horizontal login_Pageform"
                          >
                            <Row className=" gy-3">
                              <Col xl={12}>
                                <div className="mb-4">
                                  <label htmlFor="username">
                                    Username
                                    <span className="text-danger"> *</span>
                                  </label>
                                  <input
                                    name="username"
                                    type="text"
                                    onChange={handleonchange}
                                    className="form-control form-control-lg"
                                    placeholder="Enter Username"
                                    required
                                  />
                                </div>
                                <div className="mb-3">
                                  <label htmlFor="password">
                                    Password
                                    <span className="text-danger"> *</span>
                                  </label>
                                  <input
                                    name="pwd"
                                    type="password"
                                    onChange={handleonchange}
                                    className="form-control form-control-lg"
                                    placeholder="Enter Password"
                                    required
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-4 my-4 gy-3">
                              <div className="col-xl-12 d-grid mt-2">
                                <button
                                  className="btn  waves-effect waves-light"
                                  type="submit"
                                >
                                  Log In
                                  <i className="fa-solid fa-arrow-right-to-bracket"></i>
                                </button>
                              </div>
                            </Row>
                          </form>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Login;
