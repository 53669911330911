import React from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, NavLink } from 'react-router-dom';

const SidebarMenu = ({ collapseData }) => {

    return (
        <>
            <Sidebar collapsed={collapseData} className='pt-2'>
                <div className={collapseData === false ? `navbar-brand-box sidebar_logo_here` : `sidebar_icon_here`}>
                    <Link to="/Dashboard" className="logo logo-light ">
                        <span className="logo-lg">
                            {collapseData === false ? <img src="/assets/images/logo.png" alt="" /> : <img src="/assets/images/icon.png" alt="" />}
                        </span>
                        {/* <span className='h3 text-light'>WorkDone</span> */}
                    </Link>
                </div>
                <Menu className='pt-3'>
                    <MenuItem component={<NavLink to="/dashboard" />}>
                        <i className="bx bx-home-circle" /> Dashboard
                    </MenuItem>
                    <MenuItem component={<NavLink to="/total_downloads" />}>
                        <i className="bx bx-down-arrow" /> Total Downloads
                    </MenuItem>
                    {/* <MenuItem component={<NavLink to="/providers" />}>
                        <i className="bx bx-user-circle" /> Providers
                    </MenuItem> */}
                    <MenuItem component={<NavLink to="/banner" />}>
                        <i className="bx bx-image" /> Banners
                    </MenuItem>
                    <MenuItem component={<NavLink to="/category" />}>
                        <i className="bx bx-news" /> Category
                    </MenuItem>
                    {/* <MenuItem component={<NavLink to="/Advertising_competition" />}>
                        <i className="bx bx-award" /> Advertising Competition
                    </MenuItem>
                     */}
                    <MenuItem component={<NavLink to="/serviceRequest" />}>
                        <i className="bx bx-analyse" /> Pending Request
                    </MenuItem>
                    <MenuItem component={<NavLink to="/settings" />}>
                        <i className="bx bx-cog" /> Settings
                    </MenuItem>
                    {/* <SubMenu icon={ <i className="bx bx-chart" />} label="Charts">
                        <MenuItem> Pie charts</MenuItem>
                        <MenuItem> Line charts</MenuItem>
                        <MenuItem> Bar charts</MenuItem>
                    </SubMenu> */}
                </Menu>
            </Sidebar>
        </>
    )
}

export default SidebarMenu
