import React, { useEffect, useState } from "react";
import Breadcrumb from "../Common/Breadcrumb";
import { Card, Row, Col, CardBody, Table } from "reactstrap";
import CustomerTable from "../Components/DataTables/CustomerTable";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import PrintTable from "../Components/PrintTable";

const AllCustomers = () => {
  const [all_customers, setAll_customers] = useState([]);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [filterquery, setFilterquery] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [isprovider, setIsprovider] = useState();
  const [allCategory, setAllCategory] = useState([]);

  const [querycategory, setQuerycategory] = useState("");
  const [filterCatData, setFilterCatData] = useState([]);
  const token = localStorage.getItem("authToken");
  const api_url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    document.title = "WorkDone App | Total Downloads"
    fetch_customers();
    fetchCategory();
  }, []);
  const fetch_customers = async () => {
    try {
      const response = await fetch(`${api_url}/customers/getall_customers`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const results = await response.json();
      const dataHere = results.data;
      setAll_customers([...dataHere].reverse());
    } catch (err) {
      console.log(err, "customers fetching error");
    }
  };
  const handlesearch = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    const filteredResults = all_customers.filter(
      (item) =>
        item.first_name?.toLowerCase().includes(inputValue.toLowerCase()) ||
        item.last_name?.toLowerCase().includes(inputValue.toLowerCase()) ||
        item.mobile?.toLowerCase().includes(inputValue.toLowerCase()) ||
        item.email?.toLowerCase().includes(inputValue.toLowerCase()) ||
        item.address?.toLowerCase().includes(inputValue.toLowerCase()) ||
        item.gender?.toLowerCase().includes(inputValue.toLowerCase()) ||
        item.date_of_birth?.toLowerCase().includes(inputValue.toLowerCase()) ||
        item.company_name?.toLowerCase().includes(inputValue.toLowerCase()) ||
        item.company_address?.toLowerCase().includes(inputValue.toLowerCase())
    );
    setResults(filteredResults);
  };
  const handleFilter = (e) => {
    const value = e.target.value;
    setFilterquery(value);
    if (value === 'All') {
      setIsprovider(true);
      setFilterCatData(all_customers);
      setFilterData(all_customers);
    } else {
      const isProvider = value === 'true';
      if (isProvider === true) {
        setIsprovider(true);
        const filtered_c = all_customers.filter(item => item.is_provider === isProvider);
        setFilterCatData(filtered_c);
      } else if (isProvider === false) {
        setIsprovider(false);
        const filtered_c = all_customers.filter(item => item.is_provider === isProvider);
        setFilterCatData(filtered_c);
      }
      const filtered = all_customers.filter(item => item.is_provider === isProvider);
      setFilterData(filtered);
    }
  };
  const fetchCategory = async () => {
    try {
      const payload = { is_active: true };
      const response = await fetch(`${api_url}/servicecategories/getall`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      const results = await response.json();
      const dataHere = results.data;
      setAllCategory([...dataHere].reverse());
    } catch (err) {
      console.log(err, "images fetching error");
    }
  };
  const categoryFilter = (e) => {
    const value = e.target.value;
    setQuerycategory(value);
    if (value === 'All') {
      setFilterCatData(all_customers);
    } else {
      if (isprovider === true) {
        const filtered = all_customers.filter(item =>
          item.service_categories.some(category => category.name === value)
        );
        setFilterCatData(filtered);
      } else {
        const filtered = all_customers.filter(item =>
          item.service_categories.some(category => category.name === value)
        );
        setFilterCatData(filtered);
      }
    }
  };
  return (
    <>
      <Breadcrumb title="Dashboard" breadcrumbItem="Total Downloads" />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row className="mt-2">
                <Col md={8} className="col-12">
                  <h5>View Here All  Downloads!</h5>
                  <div className="mt-3">
                    {/* <p className="text_bold"> Showing All  <span className="text-primary"> {all_customers.length} </span> </p> */}
                    <div className="  mt-2">
                      <div className="btn-group" role="group" aria-label="Basic example">
                        <ReactHTMLTableToExcel id="test-table-xls-button" className="btn btn-outline-primary active" table="table-to-xls"
                          filename="totalDownloads" sheet="tablexls" buttonText=<span>Export <i className="bx bx-export"> </i></span> />
                        <PrintTable />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} className="col-12 mt-2 text-end">
                  <div className="searching_optipn text-end">
                    <Row>
                      <Col md={4} className="col-4 mt-1 text-left">
                        <label htmlFor="searchiong">Search:</label>
                      </Col>
                      <Col md={8} className="col-8">
                        <input
                          type="text"
                          onChange={handlesearch}
                          value={query}
                          placeholder="Search Here"
                          className="form-control"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={4} className="col-4 mt-1 text-left">
                        <label htmlFor="searchiong">Filter:</label>
                      </Col>
                      <Col md={8} className="col-8">
                        <select className="form-control" onChange={handleFilter}>
                          <option value="All">All</option>
                          <option value="true">Service Provider</option>
                          <option value="false">Service Seeker</option>
                        </select>
                        <small className="fw-bold">{filterData?.length === 0 ? all_customers?.length : filterData?.length}</small>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <hr />
              <div className="table-responsive " id="table-to-print">
                <h2 className="print-heading">Total Downloads</h2>
                <Table id="table-to-xls" className="table align-middle table-nowrap  w-100 mb-0 table-bordered table-hover  usersTables">
                  <thead className="table-light">
                    <tr>
                      <th>S.N</th>
                      <th className="align-middle"> Profile</th>
                      <th className="align-middle">
                        {/* Service Categories  <br/> */}
                        <span>
                          <select name="status" className='table_select_Css' onChange={categoryFilter}>
                            <option selected disabled>Service Categories</option>
                            <option value="All">All</option>
                            {allCategory.map((item, idx) =>
                              <option key={idx} value={item.name}>{item.name}</option>
                            )}
                          </select>
                        </span>
                      </th>
                      <th className="text-center"> Name</th>
                      <th className="text-center">Mobile</th>
                      <th className="text-center">Email</th>
                      <th className="text-center">Address</th>
                      <th className="text-center">Provider Status</th>
                      <th className="text-center">Provider Type</th>
                      <th className="text-center">Gender</th>
                      <th className="text-center">Date Of Birth</th>
                      <th className="text-center">Company Name</th>
                      <th className="text-center">Company Address</th>

                      <th className="text-center">Bank Details</th>
                      <th className="text-center">Documents</th>
                      <th className="text-center no_print">Verify</th>
                      <th className="text-center no_print">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {query ? (
                      <CustomerTable Customer_data={results} />
                    ) : filterCatData.length > 0 ? (
                      <CustomerTable Customer_data={filterCatData} />
                    ) : filterquery ? (
                      <CustomerTable Customer_data={filterData} />
                    ) : querycategory ? (
                      <CustomerTable Customer_data={filterCatData} />
                    ) : (
                      <CustomerTable Customer_data={all_customers} />
                    )}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AllCustomers;
