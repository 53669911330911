import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Collapse,
  CardBody,
  Card,
} from "reactstrap";
import Swal from "sweetalert2";

const CustomerTable = ({ Customer_data }) => {
  const [modal, setModal] = useState(false);
  const [doc_data, setDoc_data] = useState();
  const [isOpen, setIsOpen] = useState([]);
  const [open_bankD, setOpen_bankD] = useState([]);


  const open_Collapse = (index) => {
    if (isOpen.includes(index)) {
      setIsOpen(isOpen.filter((item) => item !== index));
    } else {
      setIsOpen([...isOpen, index]);
    }
  };
  const open_bankDetails = (index) => {
    if (open_bankD.includes(index)) {
      setOpen_bankD(open_bankD.filter((item) => item !== index));
    } else {
      setOpen_bankD([...open_bankD, index]);
    }
  };
  const toggle = () => setModal(!modal);
  const token = localStorage.getItem("authToken");
  const api_url = process.env.REACT_APP_BASE_URL;

  const View_Doc = async (id) => {
    toggle();
    try {
      const response = await fetch(`${api_url}/customers/getBy_id/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const results = await response.json();
        const document_file = results.data[0].documents[0];
        setDoc_data(document_file);
      }
    } catch (err) {
      console.log(err, "customers fetching error");
    }
  };
  const delete_customer = async (c_id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
    });
    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `${api_url}/customers/dlt_customers/${c_id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const newdata = await response.json();
        if (response.ok) {
          Swal.fire({
            title: "Deleted!",
            text: newdata.message,
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "Something went wrong",
            icon: "error",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
        });
      }
    }
  };
  return (
    <>
      {Customer_data && Customer_data.length > 0 ? (
        Customer_data.map((data, idx) => (
          <tr key={idx}>
            <td>{idx + 1}</td>
            <td>
              <div className="category_icon">
                <h1></h1>
                <img src={data.profile_picture ? data.profile_picture : "/assets/images/user.jpg"}
                  //onClick={() => getBy_id_Data(data._id, 'icon')}
                  alt=""
                />
              </div>
            </td>
            <td>
              {data.service_categories?.map((category, index) => (
                <div key={index}>
                  <span>{category.name}</span>
                </div>
              ))}
            </td>
            <td>
              {data.first_name} {data.last_name}
            </td>
            <td>{data.mobile}</td>
            <td>{data.email}</td>
            <td>{data.address}</td>
            <td>{data.is_provider === true ? "Yes" : "No"}</td>
            <td>{data.is_provider === true ? data.provider_type : "---"}</td>
            <td>{data.gender}</td>
            <td>{data.date_of_birth}</td>
            <td>{data.company_name}</td>
            <td>{data.company_address} </td>


            <td className=" align-middle">
              {data.bank_account_details ? (
                <>
                  {open_bankD.includes(idx) ? (
                    <span
                      className="badge bg-warning rounded-pill "
                      onClick={() => open_bankDetails(idx)}
                    >
                      Hide Documents
                    </span>
                  ) : (
                    <span
                      className="badge bg-info rounded-pill "
                      onClick={() => open_bankDetails(idx)}
                    >
                      View Documents
                    </span>
                  )}

                  <Collapse isOpen={open_bankD.includes(idx)} className="mt-3">
                    <Card>
                      <CardBody>
                        <table className="table align-middle table-nowrap customer_table_here table-responsive  w-100 mb-0 table-bordered">
                          <thead>
                            <tr>
                              <th> Account Name</th>
                              <td>
                                {data.bank_account_details.account_holder_name}
                              </td>
                            </tr>
                            <tr>
                              <th>Account Number</th>
                              <td>
                                {data.bank_account_details.account_number}
                              </td>
                            </tr>
                            <tr>
                              <th> Ifsc Code </th>{" "}
                              <td>{data.bank_account_details.ifsc_code}</td>
                            </tr>
                            <tr>
                              <th>Bank name</th>{" "}
                              <td>{data.bank_account_details.bank_name}</td>
                            </tr>
                            <tr>
                              <th>Branch Name</th>
                              <td>{data.bank_account_details.branch_name}</td>
                            </tr>
                          </thead>
                        </table>
                      </CardBody>
                    </Card>
                  </Collapse>
                </>
              ) : (
                <span className=" badge bg-secondary rounded-pill">
                  No Document
                </span>
              )}
            </td>
            <td className="text-center align-middle">
              {data.documents.length > 0 ? (
                <>
                  {isOpen.includes(idx) ? (
                    <span
                      className="badge bg-warning rounded-pill "
                      onClick={() => open_Collapse(idx)}
                    >
                      Hide Documents
                    </span>
                  ) : (
                    <span
                      className="badge bg-info rounded-pill "
                      onClick={() => open_Collapse(idx)}
                    >
                      View Documents
                    </span>
                  )}

                  <Collapse isOpen={isOpen.includes(idx)} className="mt-3">
                    <Card>
                      <CardBody>
                        <table className="table align-middle table-nowrap customer_table_here table-responsive  w-100 mb-0 table-bordered">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Document Name</th>
                              <th>Document Number</th>
                              <th>Document Image</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.documents?.map((doc, idx) => (
                              <tr>
                                <td>{idx + 1}</td>
                                <td>{doc.name}</td>
                                <td>{doc.number}</td>
                                <td className="text-center">
                                  <Link
                                    to={""}
                                    onClick={() => View_Doc(doc._id)}
                                    className="fw-bold"
                                  >
                                    View{" "}
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </CardBody>
                    </Card>
                  </Collapse>
                </>
              ) : (
                <span className=" badge bg-secondary rounded-pill">
                  No Document
                </span>
              )}
            </td>

            <td>
              <input type="checkbox" hidden="hidden" id="username" />
              <label className="switch" htmlFor="username"></label>
            </td>
            <td>
              <Link
                to={""}
                //  onClick={(e) => delete_customer(data._id)}
                className="badge-soft-danger badge text-danger p-1"
              >
                <i className="bx bxs-trash"></i>
              </Link>
              &emsp;
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="15" className="react-bs-table-no-data">
            No data to display.
          </td>
        </tr>
      )}

      <Modal isOpen={modal} size="md" toggle={toggle}>
        <ModalHeader toggle={toggle}> {doc_data?.name}</ModalHeader>
        <ModalBody>
          <div className="docImg_here">
            {doc_data ? (
              <img src={doc_data?.url} alt="" />
            ) : (
              <img
                src="https://nexusfrontier.tech/wp-content/themes/nexus/images/sample.jpg"
                alt=""
              />
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CustomerTable;
