// PrivateRoute.js
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../Common/VerticalLayout/Header';
import Footer from "../Common/Footer"
import { Container } from 'reactstrap';
import MediaQuery from 'react-responsive'

const PrivateRoute = ({ Component }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [menucollapsed, setMenucollapsed] = useState(true);

    const handleMenu = () => {
        setCollapsed(!collapsed);
        setMenucollapsed(!menucollapsed);
    };
    // const toggleSidebar = () => {
    //     setCollapseData(!collapsed);
    // };
    const getAuthToken = () => {
        return localStorage.getItem('authToken');
    };
    const isAuthenticated = !!getAuthToken();

    return isAuthenticated ?
        (
            <>
                <MediaQuery maxWidth={992}>
                    <Header collapsed={menucollapsed} handleMenu={handleMenu} />
                </MediaQuery>
                <MediaQuery minWidth={991} maxWidth={3000}>
                    <Header collapsed={collapsed} handleMenu={handleMenu} />
                </MediaQuery>
                <div id="layout-wrapper">
                    <div className={collapsed === false ? `main-content ` : 'main-content_removeMargin'}>
                        <div className="page-content">
                            <Container fluid={true}>
                                <Component />
                            </Container>
                        </div>
                        <Footer />
                    </div>
                </div>
               
            </>

        ) : <Navigate to="/" replace={true} />;
};

export default PrivateRoute;