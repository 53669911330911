import React, { useEffect, useState } from 'react'
import { Card, Row, Col, CardBody, Table } from 'reactstrap'
import Breadcrumb from '../Common/Breadcrumb'

const Settings = () => {
    const [allData, setAllData] = useState([]);

    const token = localStorage.getItem('authToken');
    const api_url = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        document.title = "WorkDone App | Settings"
        fetchImages();
    }, []);
    const fetchImages = async () => {
        try {
            const payload = { is_active: true }
            const response = await fetch(`${api_url}/settings/getData`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });
            const results = await response.json();
            setAllData(results.data);
        } catch (err) {
            console.log(err, 'images fetching error')
        }
    };
    return (
        <>
            <Breadcrumb title="Dashboard" breadcrumbItem="Settings" />
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <hr />
                            <div className="table-responsive scrollableTable">
                                <Table className="table align-middle table-nowrap  w-100 mb-0 table-bordered table-hover  usersTables">
                                    <thead className="table-light">
                                        <tr>
                                            <th>S.N</th>
                                            <th className="align-middle">Seeker Platform Charges </th>
                                            <th className="align-middle">Provider Platform Charges </th>
                                            <th className="align-middle">Payment Charges </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allData && allData.length > 0 ?
                                            allData.map((data, idx) => (
                                                <tr key={idx}>
                                                    <td>{idx + 1}</td>
                                                    <td>{data.seeker_platform_charges_percent} %</td>
                                                    <td>{data.provider_platform_charges_percent} %</td>
                                                    <td>{data.payment_charges_percent} %</td>
                                                </tr>
                                            ))
                                            : ''
                                        }

                                    </tbody>
                                    <tbody>
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Settings
