import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Row, Col, Modal, ModalBody, ModalHeader, FormGroup, Input } from "reactstrap";
import { useEffect, useState } from "react";

const CategoryTable = ({ categoryData }) => {
  const [modal, setModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [thirdModal, setThirdModal] = useState(false);
  const [getById_Data, setGetById_Data] = useState();
  const [show_btn, setShow_btn] = useState(false);
  const [update_icon, setUpdate_icon] = useState();
  const [update_Cimg, setUpdate_Cimg] = useState();
  const [pCounts, setPCounts] = useState();

  const toggle = () => setModal(!modal);
  const toggletwo = () => setSecondModal(!secondModal);
  const togglethree = () => setThirdModal(!thirdModal);

  const [data, setData] = useState();
  useEffect(() => {
    setData(categoryData);
    provider_count();
  }, [categoryData]);

  const token = localStorage.getItem("authToken");
  const api_url = process.env.REACT_APP_BASE_URL;
  // const api_url = "http://localhost:3000";


  const getBy_id_Data = async (id, type) => {
    if (type === "detail") {
      toggle();
    } else if (type === "icon") {
      toggletwo();
    } else if (type === "image") {
      togglethree();
    }
    try {
      const response = await fetch(`${api_url}/servicecategories/get_by_id`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id }),
      });
      if (response.status === 200) {
        const result = await response.json();
        setGetById_Data(result.data);
      } else {
        console.log("server error");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const up_onChange = (e) => {
    const { name, value } = e.target;
    setGetById_Data((pre) => ({ ...pre, [name]: value }));
    setShow_btn(true);
  };
  const handle_update = async (id) => {
    try {
      const payload = {
        id: id,
        name: getById_Data.name,
        title: getById_Data.title,
        sub_title: getById_Data.sub_title,
      };
      const response_two = await fetch(`${api_url}/servicecategories/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response_two.ok) {
        const updatedData = await response_two.json();
        Swal.fire({
          title: "success!",
          text: " Title Update Successfully!",
          icon: "success",
        });
        setData((pre) =>
          pre.map((items) =>
            items._id === updatedData._id ? { ...items, ...updatedData } : items
          )
        );
        setShow_btn(false);
        setModal(false);
      } else {
        Swal.fire({
          title: "Error!",
          text: " Something went wrong!",
          icon: "error",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const update_onChange = (e) => {
    setUpdate_icon(e.target.files[0]);
    setShow_btn(true);
  };
  const updated_icon = async (id) => {
    try {
      const formDataone = new FormData();
      formDataone.append("id", id);
      formDataone.append("document_name", "icon");
      formDataone.append("document_file", update_icon);
      const response = await fetch(
        `${api_url}/servicecategories/upload_document`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formDataone,
        }
      );
      if (response.status === 200) {
        Swal.fire({
          title: "success!",
          text: " Category Icon Update Successfully!",
          icon: "success",
        });
        setShow_btn(false);
        setSecondModal(false);
      } else {
        Swal.fire({
          title: "Error!",
          text: " Something went wrong!",
          icon: "error",
        });
        setSecondModal(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handle_img_onchange = (e) => {
    setUpdate_Cimg(e.target.files[0]);
    setShow_btn(true);
  };
  const updated_categoryimg = async (id) => {
    try {
      const formDatatwo = new FormData();
      formDatatwo.append("id", id);
      formDatatwo.append("document_name", "image");
      formDatatwo.append("document_file", update_Cimg);
      console.log(formDatatwo, "formDatatwo");
      const response = await fetch(
        `${api_url}/servicecategories/upload_document`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formDatatwo,
        }
      );
      if (response.status === 200) {
        Swal.fire({
          title: "success!",
          text: " Category Image Update Successfully!",
          icon: "success",
        });
        setShow_btn(false);
        setThirdModal(false);
      } else {
        Swal.fire({
          title: "Error!",
          text: " Something went wrong!",
          icon: "error",
        });
        setThirdModal(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSwitchChange = async (c_data) => {
    const payload = {
      id: c_data._id,
      is_active: c_data.is_active === true ? false : true
    }
    try {
      const response = await fetch(`${api_url}/servicecategories/activate_deactivate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      if (result.status === 1) {
        Swal.fire({
          title: result.message,
          text: result.message,
          icon: "success",
        });
        const updatedData = data.filter((item) => item._id !== c_data._id);
        setData(updatedData);
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Something went wrong!',
          icon: "error",
        });
      }
    } catch (err) {
      console.log(err, 'errror');
    }
  };
  const provider_count = async () => {
    try {
      const response = await fetch(`${api_url}/servicecategories/provider_counts`, {
        method: "GET",
      });
      if (response.status === 200) {
        const results = await response.json();
        setPCounts(results);
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      {data && data.length > 0 ? (
        data.map((data, idx) => (
          <tr key={idx}>
            <td>{idx + 1}</td>
            <td>
              <div className="category_icon">
                <img
                  src={data.icon_url}
                  //  alt={data.icon_url}
                  onClick={() => getBy_id_Data(data._id, "icon")}
                />
              </div>
            </td>
            <td>
              <div className="Banner_img_here">
                <img
                  src={data.image_url}
                  //  alt={data.image_url}
                  onClick={() => getBy_id_Data(data._id, "image")}
                />
              </div>
            </td>
            <td>{data.name} </td>
            <td>{data.title} </td>
            <td>{data.sub_title} </td>
            <td>{pCounts ?
              pCounts.map((item) => (
                <span>{data.name === item.name ? item.customerCount : ''}</span>
              ))
              : ''
            }</td>
            <td>
              <div className="form-switch " >
                <input
                  type="checkbox"
                  style={{ height: "15px", width: "30px" }}
                  title={data.is_active ? "Click to Deactivate ." : "Activate"}
                  className="form-check-input"
                  onClick={() => {
                    handleSwitchChange(data)
                  }}
                  checked={data.is_active}
                />
                &emsp;
                <Link
                  to={""}
                  onClick={() => getBy_id_Data(data._id, "detail")}
                  className="badge-soft-success badge  text-success p-1"
                >
                  <i className="bx bxs-edit"></i>
                </Link>
              </div>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="15" className="react-bs-table-no-data">
            No data to display.
          </td>
        </tr>
      )}
      {/* ------update modal------- */}
      <Modal isOpen={modal} size="md" toggle={toggle}>
        <ModalHeader toggle={toggle}> Update Details</ModalHeader>
        <ModalBody>
          <div className="upadteform">
            {getById_Data && (
              <form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={getById_Data.name}
                        onChange={up_onChange}
                        name="name"
                        id="name"
                        placeholder="Enter name"
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label htmlFor="title" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={getById_Data.title}
                        onChange={up_onChange}
                        name="title"
                        id="title"
                        placeholder="Enter Title"
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label htmlFor="sub_title" className="form-label">
                        Sub Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={getById_Data.sub_title}
                        onChange={up_onChange}
                        name="sub_title"
                        id="sub_title"
                        placeholder="Enter Sub Title"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="mb-3 text-center buTTon_center mt-4">
                  {show_btn == true ? (
                    <button
                      type="submit"
                      className="btn btn-info"
                      onClick={() => handle_update(getById_Data._id)}
                    >
                      Update <i className="bx bx-edit-alt"></i>
                    </button>
                  ) : (
                    <button type="submit" disabled className="btn btn-info">
                      Update <i className="bx bx-edit-alt"></i>
                    </button>
                  )}
                </div>
              </form>
            )}
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={secondModal} size="md" toggle={toggletwo}>
        <ModalHeader toggle={toggletwo}> Update Category Icon</ModalHeader>
        <ModalBody>
          <div className="upadteform">
            {getById_Data && (
              <form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col md={12} className="text-center mb-4">
                    <div className="category_iconimg ">
                      <img src={getById_Data.icon_url} alt="" />
                    </div>
                  </Col>
                  <Col md={2} sm={2}></Col>
                  <Col md={8} sm={8}>
                    <div className="mb-3">
                      <input
                        type="file"
                        className="form-control"
                        onChange={update_onChange}
                        name="document_file"
                        id="document_file"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <div className="mb-3 buTTon_center text-center mt-4">
                  {show_btn == true ? (
                    <button
                      type="submit"
                      className="btn btn-info"
                      onClick={() => updated_icon(getById_Data._id)}
                    >
                      Update <i className="bx bx-edit-alt"></i>
                    </button>
                  ) : (
                    <button type="submit" disabled className="btn btn-info">
                      Update <i className="bx bx-edit-alt"></i>
                    </button>
                  )}
                </div>
              </form>
            )}
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={thirdModal} size="md" toggle={togglethree}>
        <ModalHeader toggle={togglethree}> Update Category Image</ModalHeader>
        <ModalBody>
          <div className="upadteform">
            {getById_Data && (
              <form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col md={12} className="text-center mb-4">
                    <div className="Banner_img_here ">
                      <img src={getById_Data.image_url} alt="" />
                    </div>
                  </Col>
                  <Col md={2} sm={2}></Col>
                  <Col md={8} sm={8}>
                    <div className="mb-3">
                      <input
                        type="file"
                        className="form-control"
                        onChange={handle_img_onchange}
                        name="document_img"
                        id="document_img"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <div className="mb-3 text-center buTTon_center mt-4">
                  {show_btn == true ? (
                    <button
                      type="submit"
                      className="btn btn-info"
                      onClick={() => updated_categoryimg(getById_Data._id)}
                    >
                      Update <i className="bx bx-edit-alt"></i>
                    </button>
                  ) : (
                    <button type="submit" disabled className="btn btn-info">
                      Update <i className="bx bx-edit-alt"></i>
                    </button>
                  )}
                </div>
              </form>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CategoryTable;
