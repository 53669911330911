import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Row,
  Col,
  CardBody,
  Container,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Breadcrumb from "../Common/Breadcrumb";
import Swal from "sweetalert2";
import BannerTable from "../Components/DataTables/BannerTable";
import Select from 'react-select';

const Banner = () => {
  const refFrom = useRef(null);
  const [document_file, setDocument_file] = useState();
  const [title, setTitle] = useState("");
  const [provider_id, setProvider_id] = useState();
  const [newData, setNewData] = useState([]);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [allProviders, setAllProviders] = useState();
  const token = localStorage.getItem("authToken");
  const api_url = process.env.REACT_APP_BASE_URL;
  // const api_url = 'http://localhost:3000';

  useEffect(() => {
    document.title = "WorkDone App | Banners";
    fetchImages();
    fetch_providers();
  }, []);

  const fetch_providers = async () => {
    try {
      const response = await fetch(`${api_url}/banners/getall_providers`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const results = await response.json();
      const dataHere = results.data;
      setAllProviders(dataHere);
    } catch (err) {
      console.log(err, 'customers fetching error')
    }
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    try {
      const payload = { title: title, provider_id };
      const responseOne = await fetch(`${api_url}/banners/insert`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (responseOne.status === 200) {
        const reslt = await responseOne.json();
        const bannerId = reslt.data._id;
        const formData = new FormData();
        formData.append("id", bannerId);
        formData.append("document_file", document_file);
        const response = await fetch(`${api_url}/banners/upload_document`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        if (response.status === 200) {
          Swal.fire({
            title: "success!",
            text: " Banner Add Successfully!",
            icon: "success",
          });
          fetchImages();
          setTitle(" ");
          setProvider_id('');
          refFrom.current.reset();
        } else {
          console.log("server error");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchImages = async () => {
    try {
      const payload = { is_active: true };
      const response = await fetch(`${api_url}/banners/getall`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      const results = await response.json();
      const dataHere = results.data;
      setNewData([...dataHere].reverse());
    } catch (err) {
      console.log(err, "images fetching error");
    }
  };

  const handlesearch = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    const filteredResults = newData.filter((item) =>
      item.title.toLowerCase().includes(inputValue.toLowerCase())
    );
    setResults(filteredResults);
  };
  return (
    <>
      <Breadcrumb title="Dashboard" breadcrumbItem="Banners" />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <div className="add_content_form pt-1 mb-3">
                <h4>Add Banner</h4>
                <form
                  onSubmit={handleUpload}
                  ref={refFrom}
                  className="suggestionpage contactform mt-3"
                >
                  <Row>
                    <Col md={3} sm={4}>
                      <div className="mb-3">
                        <label htmlFor="document_file" className="form-label">
                          Choose Image <span className="text-danger">*</span>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name="document_file"
                          onChange={(e) => setDocument_file(e.target.files[0])}
                          id="document_file"
                          required
                        />
                      </div>
                    </Col>
                    <Col md={3} sm={4}>
                      <div className="mb-3">
                        <label htmlFor="title" className="form-label">
                          {" "}
                          Title <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Title Here!"
                          value={title}
                          name="title"
                          onChange={(e) => setTitle(e.target.value)}
                          id="title"
                          required
                        />
                      </div>
                    </Col>
                    <Col md={3} sm={4}>
                      <div className="mb-3">
                        <label htmlFor="provider_id" className="form-label">  Select Providers  </label>
                        <Select
                          name="provider_id"
                          id="provider_id"
                          options={allProviders?.map(prd => ({
                            value: prd._id,
                            label: prd.first_name +'  '+ prd.last_name
                          }))}
                          onChange={(selectedOption) => {
                            setProvider_id(selectedOption.value);
                          }}
                          placeholder="Select Providers"
                          isSearchable={true}
                        />
                      </div>
                    </Col>
                    <Col md={3} sm={4} className="mt-4 pt-1">
                      <Button color="primary" type="submit">
                        Add Banner <i className="bx bx-plus-circle"></i>
                      </Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row className="mt-2">
                <Col md={8}>
                  <h4>All Banners</h4>
                  <div className="mt-3">
                    {/* <p className="text_bold">
                      Showing All Entries:{" "}
                      <span className="text-primary"> {newData.length}</span>
                    </p> */}
                  </div>
                </Col>
                <Col md={4} className="text-end">
                  <div className="searching_optipn text-end">
                    <Row>
                      <Col md={4} className="mt-1 text-left">
                        <label htmlFor="searchiong">Search:</label>
                      </Col>
                      <Col md={8}>
                        <input
                          type="text"
                          value={query}
                          onChange={handlesearch}
                          placeholder="Search Here"
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <hr />
              <div className="table-responsive scrollableTable">
                <Table className="table align-middle table-nowrap  w-100 mb-0 table-bordered table-hover  usersTables">
                  <thead className="table-light">
                    <tr>
                      <th>S.N</th>
                      <th className="align-middle">Title</th>
                      <th className="align-middle">Image</th>
                      <th className="align-middle">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {query ? (
                      <BannerTable alldata={results} />
                    ) : (
                      <BannerTable alldata={newData} />
                    )}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Banner;
