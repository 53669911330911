import React from 'react'
import { Link } from 'react-router-dom'


const PrintTable = () => {
    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<html><head><title>Company Management System </title>');
        printWindow.document.write('<link rel="icon"  href="%PUBLIC_URL%/favicon.png" />');
        printWindow.document.write(`
              <style>
                table { width: 100%; border-collapse: collapse; }
                th, td { border: 1px solid black; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
                input{display:none}
                .no_print { display: none; }
              </style>
            `);
        printWindow.document.write('</head><body>');
        printWindow.document.write('<div id="print-content"></div>');
        printWindow.document.write('<script>');
        printWindow.document.write(`
              window.onload = function() {
                var favicon = document.createElement('link');
                favicon.rel = 'shortcut icon';
                favicon.href = '%PUBLIC_URL%/favicon.png'; 
                document.head.appendChild(favicon);
        
                const printContent = document.getElementById('print-content');
                const table = window.opener.document.getElementById('table-to-print').cloneNode(true);
                printContent.appendChild(table);
        
                window.print();
        
                setTimeout(function() {
                  window.close();
                }, 300); 
              };
            `);
        printWindow.document.write('</script>');
        printWindow.document.write('</body></html>');
        printWindow.document.close();
    };
    return (
        <>
            <Link to={''} className="btn btn-outline-primary" onClick={handlePrint}>  Print <i className="fa fa-print"></i> </Link>

        </>
    )
}

export default PrintTable
