import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

const WelcomeCard = () => {
    return (
        <>
            <Col xl={4} >
                <Card className=" overflow-hidden">
                    <div className="">
                        <Row className="row">
                            <Col sm={12} className="col-7 mb-3">
                                <div className="text-primary p-3">
                                    <h3 className=" mt-3 h3" style={{color:'#0a0442'}}>WorkDone App Dashboard </h3>
                                    <p className="text-muted mb-0 h5">Admin</p>
                                </div>
                            </Col>
                            {/* <Col sm={4} className="col-5 align-self-end">
                                <img src="assets/images/profile-img.png" alt="" className="img-fluid" />
                            </Col> */}
                        </Row>
                    </div>
                    <CardBody className=" pt-0 bg-primary-subtle">
                        <Row >
                            <Col sm={3}>
                                <div className="avatar-md profile-user-wid mb-4">
                                    <img
                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQR6t92ndjWipGJBWsCwPlRcxgn9c7bXAkem37W7QuNnhejSKiJDtoZbg5jD1mKLDi65Go&usqp=CAU" alt=""
                                        className="img-thumbnail rounded-circle"
                                    />
                                </div>
                            </Col>
                            <Col sm={9}>
                                <div className="pt-4">
                                    <Row className="row pb-2 ">
                                        <Col md={7}>
                                            <h2 className="font-size-19 h3 text-primary">Welcome Back !</h2>
                                           
                                        </Col>
                                        <Col sm={5} className="col-5 align-self-end">
                                            <img src="assets/images/profile-img.png" alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>

        </>
    )
}

export default WelcomeCard
