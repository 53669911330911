import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2'
import { Tooltip as ReactTooltip } from "react-tooltip";

const Advertising_comTable = ({ userData }) => {
    const [modal, setModal] = useState(false);
    const [secondModal, setSecondModal] = useState(false);
    const [thirdmodal, setThirdmodal] = useState(false);

    const [getByIdData, setGetByIdData] = useState([]);
    const [pay_date, setPay_date] = useState();

    const toggle = () => setModal(!modal);
    const toggletwo = () => setSecondModal(!secondModal);
    const togglethree = () => setThirdmodal(!thirdmodal);

    const token = localStorage.getItem('authToken');
    const api_url = process.env.REACT_APP_BASE_URL;

    const getById = async (id, type) => {
        if (type === 'after_paid') {
            toggle();
        } else if (type === 'partici_detail') {
            toggletwo();
        } else if (type === 'img') {
            togglethree();
        }
        try {
            const response = await fetch(`${api_url}/interestdata/getBy_Id/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                const result = await response.json();
                const maindata = result.data;
                const formattedDate = new Date(maindata.createdAt).toLocaleString();
                setPay_date(formattedDate);
                setGetByIdData(maindata);
            } else {
                console.log('server error')
            }
        } catch (err) { console.log(err) }
    }
    return (
        <>
            {userData && userData.length > 0 ? (
                userData.map((data, idx) =>
                    <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{data.name}</td>
                        <td>{data.phone}</td>
                        <td>{data.email}</td>

                        <td className='text-center'>
                            {data.payment_status === 'Pending' ?
                                <span className='badge-soft-warning badge  text-warning p-1'>{data.payment_status}</span>
                                : data.payment_status === 'Paid' ?
                                    <>
                                        <span className='badge-soft-success badge  text-success p-1'>{data.payment_status}</span>
                                    </> : ''
                            }
                        </td>
                        {data.payment_status === 'Paid' ?
                            <>
                                <td className='text-center'>
                                    <span className='badge-soft-info badge  text-info p-1' onClick={() => getById(data._id, 'after_paid')} title='View payment details '><i className='fa fa-eye'></i></span>
                                    &emsp;   <span className='badge-soft-primary badge  text-info p-1' title="View Participants" onClick={() => getById(data._id, 'partici_detail')}><i className='fa fa-users'></i></span>
                                </td>
                                <td className='text-center'>
                                    <div className="one_img_here">
                                        <img src={data.doc_url} alt="" />
                                    </div>
                                    <span className='View_imgCss' onClick={() => getById(data._id, 'img')}>View Doc</span>
                                </td>
                            </>
                            : <>
                                <td className='text-center'>
                                    <span className='badge-soft-secondary badge  text-secondary p-1' aria-disabled title='Paiment status Pending'><i className='fa fa-eye'></i></span>
                                    &emsp;   <span className='badge-soft-primary badge  text-info p-1' onClick={() => getById(data._id, 'partici_detail')} data-tooltip-id="view_par"><i className='fa fa-users'></i></span>
                                    <ReactTooltip
                                        id="view_par"
                                        place="top"
                                        variant="info"
                                        content="View Participants"
                                    />
                                </td>
                                <td className='text-center'> <div className="one_img_here">
                                    <img src="/assets/images/noImg.png" alt="" />
                                </div>
                                    <span className='text-secondary View_imgCss' >No Doc</span></td>
                            </>}
                    </tr>
                )
            ) : (
                <tr>
                    <td colSpan="15" className="react-bs-table-no-data">No data to display.</td>
                </tr>
            )
            }


            {/* ------all modal------- */}
            <Modal isOpen={modal} size="lg" toggle={toggle} >
                <ModalHeader toggle={toggle} style={{ background: '#0b013e', color: '#fff' }}>View Details</ModalHeader>
                <ModalBody>
                    <div className="upadteform">

                        {getByIdData ?
                            <> <Row>
                                <Col><p><b>Date: </b>{pay_date} </p></Col>
                                <Col><p className='text-end'><b>Entry Fee: </b> &emsp;<b className='text-primary'>₹{getByIdData.entry_fee} </b></p></Col>
                                <hr />
                            </Row>
                                <Row>
                                    <Col><b>Entry Description:</b> &emsp;<span>{getByIdData.entry_des}</span></Col>
                                </Row><hr />
                                <Row>
                                    <Col><p><b>Payment Details: </b> </p></Col>
                                </Row>
                                <Row>
                                    <table className='table table-bordered table-responsive'>
                                        <tbody>
                                            <tr>
                                                <th>User Email</th>
                                                <td>{getByIdData.payment_details?.email}</td>
                                                <th>User Contact</th>
                                                <td>{getByIdData.payment_details?.contact}</td>
                                                <th>Payment Status</th>
                                                <td>{getByIdData.payment_details?.status === 'captured' ?
                                                    <span className='text-success'>Success</span>
                                                    : <span className='text-danger'>Faild</span>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Payment Id</th>
                                                <td>{getByIdData.payment_details?.id}</td>
                                                <th>Amount</th>
                                                <td>{getByIdData.payment_details?.amount / 100}</td>
                                                <th>Currency</th>
                                                <td>{getByIdData.payment_details?.currency}</td>
                                            </tr>
                                            <tr>
                                                <th>Order Id</th>
                                                <td>{getByIdData.payment_details?.order_id}</td>
                                                <th>Method</th>
                                                <td>{getByIdData.payment_details?.method}</td>
                                                <th>Description</th>
                                                <td>{getByIdData.payment_details?.description}</td>
                                            </tr>
                                        </tbody></table>
                                </Row>
                                <Row>
                                    <Col><p><b>Card Details: </b> </p></Col>
                                    <table className='table table-bordered table-responsive'>
                                        <tbody>
                                            <tr>
                                                <th>Card Id</th>
                                                <td>{getByIdData.payment_details?.card.id}</td>
                                                <th>Card Last 4 Digit </th>
                                                <td>{getByIdData.payment_details?.card.last4}</td>
                                            </tr>
                                            <tr>
                                                <th>Network</th>
                                                <td>{getByIdData.payment_details?.card.network}</td>
                                                <th>Type</th>
                                                <td>{getByIdData.payment_details?.card.type}</td>
                                            </tr>
                                        </tbody></table>
                                </Row>

                            </>
                            : ''}

                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={secondModal} size="md" toggle={toggletwo} >
                <ModalHeader toggle={toggletwo}> All Participants</ModalHeader>
                <ModalBody>
                    <div className="upadteform">
                        <Row>
                            {getByIdData ?
                                <table className='table table-bordered table-responsive view_userDataTable' >
                                    <thead>
                                        <tr className='bg-transparent'><th>Participant Name</th>
                                            <th>Participant Institution /Organization </th></tr>
                                    </thead>
                                    <tbody>
                                        {getByIdData.participants_data?.length > 0 ?
                                            getByIdData.participants_data.map((user, idx) =>
                                                <tr>
                                                    <td>{user.participant_name}</td>
                                                    <td>{user.participant_org}</td>
                                                </tr>
                                            ) : ''}
                                    </tbody>
                                </table>
                                : ''}
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={thirdmodal} size="sm" toggle={togglethree} >
                <ModalHeader toggle={togglethree}> View Doc Here</ModalHeader>
                <ModalBody>
                    <div className="upadteform">
                        <div className="row">
                            {getByIdData ?
                                <div className="col-md-12 col-sm-12 col-12 servi_imgCrad" >
                                    <div className="service_request_img_here">
                                        <img src={getByIdData.doc_url} alt="" />
                                    </div>
                                </div> : ''}

                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default Advertising_comTable
