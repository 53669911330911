import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom'
import SidebarMenu from './Sidebar';


const Header = ({ collapsed, handleMenu }) => {
  const navigate = useNavigate();

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  const handlelogout = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No, cancel!',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    });
    if (result.isConfirmed) {
      try {
        const token = localStorage.getItem('authToken');
        const api_url = process.env.REACT_APP_BASE_URL;
        const response = await fetch(`${api_url}/adminusers/logout`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        const results = await response.json();
        if (results.status === 1) {
          navigate('/');
          localStorage.removeItem('authToken');
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'Error ',
            icon: 'error',
          });
          console.log('errror')
        }
      } catch (err) {
        console.log(err)
      }
    }
  }
  return (
    <>
      <header id="page-topbar" className={collapsed === false ? 'TopHeader_set' : 'remove_leftMargin'}>
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-none">
              <Link to="/Dashboard" className="logo logo-light ">
                <span className="logo-lg">
                  <img src="/assets/images/whitelogo.jpg" alt="" height={50} />
                </span>&emsp; &emsp;
                <span className='h3 text-light'>WorkDone</span>
              </Link>
            </div>
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16  header-item waves-effect waves-light"
              data-bs-toggle="collapse" onClick={handleMenu}
              data-bs-target="#topnav-menu-content"
            >
              <i className="fa fa-bars text-dark" />
            </button>
            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input type="text" className="form-control" placeholder="Search..." />
                <span className="bx bx-search-alt" />
              </div>
            </form> */}
            {/* <div className="dropdown dropdown-mega d-none d-lg-block ms-2">
              <button
                type="button"
                className="btn header-item waves-effect"
                data-bs-toggle="dropdown"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <span key="t-megamenu">Reports</span>
                <i className="mdi mdi-chevron-down" />
              </button>
              <div className="dropdown-menu dropdown-megamenu">
                <div className="row">
                  <div className="col-sm-8">
                    <div className="row">
                      <div className="col-md-4">
                        <h5 className="font-size-14" key="t-ui-components">
                          UI Components
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <a href="javascript:void(0);" key="t-lightbox">
                              Lightbox
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-range-slider">
                              Range Slider
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-sweet-alert">
                              Sweet Alert
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-rating">
                              Rating
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-forms">
                              Forms
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-tables">
                              Tables
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-charts">
                              Charts
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4">
                        <h5 className="font-size-14" key="t-applications">
                          Applications
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <a href="javascript:void(0);" key="t-ecommerce">
                              Ecommerce
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-calendar">
                              Calendar
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-email">
                              Email
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-projects">
                              Projects
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-tasks">
                              Tasks
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-contacts">
                              Contacts
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4">
                        <h5 className="font-size-14" key="t-extra-pages">
                          Extra Pages
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <a href="javascript:void(0);" key="t-light-sidebar">
                              Light Sidebar
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-compact-sidebar">
                              Compact Sidebar
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-horizontal">
                              Horizontal layout
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-maintenance">
                              Maintenance
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-coming-soon">
                              Coming Soon
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-timeline">
                              Timeline
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-faqs">
                              FAQs
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="row">
                      <div className="col-sm-6">
                        <h5 className="font-size-14" key="t-ui-components">
                          UI Components
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <a href="javascript:void(0);" key="t-lightbox">
                              Lightbox
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-range-slider">
                              Range Slider
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-sweet-alert">
                              Sweet Alert
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-rating">
                              Rating
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-forms">
                              Forms
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-tables">
                              Tables
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" key="t-charts">
                              Charts
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-sm-5">
                        <div>
                          <img
                            src="assets/images/megamenu-img.png"
                            alt=""
                            className="img-fluid mx-auto d-block"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                data-bs-toggle="fullscreen" onClick={toggleFullscreen}
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  className="rounded-circle header-profile-user"
                  src="https://www.shutterstock.com/image-vector/default-avatar-profile-icon-social-600nw-1677509740.jpg"
                  alt="Header Avatar"
                />
                <span className="d-none d-xl-inline-block ms-1" key="t-henry">
                  Admin
                </span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <Link className="dropdown-item" to="/ChangePassword">
                  <i className="bx bx-user font-size-16 align-middle me-1" />
                  <span key="t-profile">Change Password</span>
                </Link>
                <div className="dropdown-divider" />
                <Link className="dropdown-item text-danger" onClick={handlelogout} to={''}>
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                  <span key="t-logout">Logout</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
      <SidebarMenu collapseData={collapsed} />
    </>
  )
}

export default Header
