import React from 'react'
import AllRoutes from './Routes/AllRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css'

function App() {

  return (
    <>
      <ToastContainer autoClose={3000} />
      <AllRoutes />
    </>
  );
}

export default App;
