import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col, CardBody, Collapse, Table, Button } from "reactstrap";
import Breadcrumb from "../Common/Breadcrumb";
import Swal from "sweetalert2";
import CategoryTable from "../Components/DataTables/CategoryTable";

const Category = () => {
  const refFrom = useRef(null);
  const [category_data, setCategory_data] = useState({
    name: "",
    title: "",
    sub_title: "",
  });
  const [category_icon, setCategory_icon] = useState();
  const [category_img, setCategory_img] = useState();
  const [newData, setnewData] = useState([]);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [all_customers, setAll_customers] = useState([]);

  const toggle = () => setIsOpen(!isOpen);
  const token = localStorage.getItem("authToken");
  const api_url = process.env.REACT_APP_BASE_URL;
  // const api_url = "http://localhost:3000"
  useEffect(() => {
    document.title = "WorkDone App | Categories";
    fetchCategory();
    fetch_customers();
  }, []);
  const fetch_customers = async () => {
    try {
      const response = await fetch(`${api_url}/customers/getall_customers`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const results = await response.json();
      const dataHere = results.data;
      setAll_customers([...dataHere].reverse());
    } catch (err) {
      console.log(err, "customers fetching error");
    }
  };
  const fetchCategory = async () => {
    try {
      const payload = { is_active: true };
      const response = await fetch(`${api_url}/servicecategories/getall`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      const results = await response.json();
      const dataHere = results.data;
      setnewData([...dataHere].reverse());
    } catch (err) {
      console.log(err, "images fetching error");
    }
  };
  const handleOnchange = (e) => {
    const { name, value } = e.target;
    const newdata = { ...category_data, [name]: value };
    setCategory_data(newdata);
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        name: category_data.name,
        title: category_data.title,
        sub_title: category_data.sub_title,
      };
      const responseOne = await fetch(`${api_url}/servicecategories/insert`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (responseOne.status === 200) {
        const reslt = await responseOne.json();
        const category_id = reslt.data._id;
        const formData = new FormData();
        formData.append("id", category_id);
        formData.append("document_name", "icon");
        formData.append("document_file", category_icon);
        const response = await fetch(
          `${api_url}/servicecategories/upload_document`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const formDataTwo = new FormData();
        formDataTwo.append("id", category_id);
        formDataTwo.append("document_name", "image");
        formDataTwo.append("document_file", category_img);
        const responselast = await fetch(
          `${api_url}/servicecategories/upload_document`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formDataTwo,
          }
        );
        console.log("upload");
        Swal.fire({
          title: "success!",
          text: " Banner Add Successfully!",
          icon: "success",
        });
        // } else {
        //   console.log('server  hgsvgcvsg error');
        // }
      }
      fetchCategory();
      refFrom.current.reset();
      setCategory_data({
        name: "",
        title: "",
        sub_title: "",
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handlesearch = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    const filteredResults = newData.filter(
      (item) =>
        item.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
        item.title?.toLowerCase().includes(inputValue.toLowerCase()) ||
        item.sub_title?.toLowerCase().includes(inputValue.toLowerCase())
    );
    setResults(filteredResults);
  };
  return (
    <>
      <Breadcrumb title="Dashboard" breadcrumbItem="Category" />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <div className="add_content_form pt-1 ">
                <div className="top_section_up d-flex justify-content-between ">
                  <h4>Add Category</h4>
                  <Button color="primary" onClick={toggle} style={{ marginBottom: '1rem' }}>
                    <i className="fa fa-plus-circle"> </i>  Add Here
                  </Button>
                </div>
                <Collapse isOpen={isOpen} className="mb-3">
                  <form onSubmit={handleUpload} ref={refFrom} className="suggestionpage contactform mt-3"  >
                    <Row>
                      <Col md={6} sm={6}>
                        <div className="mb-4">
                          <label htmlFor="categoryname" className="form-label">

                            Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Category Name!"
                            value={category_data.name}
                            name="name"
                            onChange={handleOnchange}
                            id="categoryname"
                            required
                          />
                        </div>
                      </Col>
                      <Col md={6} sm={6}>
                        <div className="mb-4">
                          <label htmlFor="title" className="form-label">

                            Title <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Category Title!"
                            value={category_data.title}
                            name="title"
                            onChange={handleOnchange}
                            id="title"
                            required
                          />
                        </div>
                      </Col>
                      <Col md={4} sm={4}>
                        <div className="mb-3">
                          <label htmlFor="sub_title" className="form-label">
                            Sub Title <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Category Sub Title!"
                            value={category_data.sub_title}
                            name="sub_title"
                            onChange={handleOnchange}
                            id="sub_title"
                            required
                          />
                        </div>
                      </Col>
                      <Col md={4} sm={4}>
                        <div className="mb-4">
                          <label htmlFor="category_icon" className="form-label">
                            Icon <span className="text-danger">*</span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="category_icon"
                            onChange={(e) => setCategory_icon(e.target.files[0])}
                            id="category_icon"
                            required
                          />
                        </div>
                      </Col>
                      <Col md={4} sm={4}>
                        <div className="mb-4">
                          <label htmlFor="category_img" className="form-label">
                            Category Image <span className="text-danger">*</span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="category_img"
                            onChange={(e) => setCategory_img(e.target.files[0])}
                            id="category_img"
                            required
                          />
                        </div>
                      </Col>
                      <Col md={12} sm={12} className="mt-2 pt-1 text-center buTTon_center">
                        <Button color="primary" type="submit">  Add Category <i className="bx bx-plus-circle"></i></Button>
                      </Col>
                    </Row>
                  </form>
                </Collapse>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row className="mt-2">
                <Col md={8}>
                  <h4>All Category</h4>
                  <div className="mt-3">
                    {/* <p className="text_bold">
                      Showing All Entries:
                      <span className="text-primary"> {newData.length}</span>
                    </p> */}
                  </div>
                </Col>
                <Col md={4} className="text-end">
                  <div className="searching_optipn text-end">
                    <Row>
                      <Col md={4} className="mt-1 text-left">
                        <label htmlFor="searchiong">Search:</label>
                      </Col>
                      <Col md={8}>
                        <input
                          type="text"
                          value={query}
                          onChange={handlesearch}
                          placeholder="Search Here"
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <hr />
              <div className="table-responsive scrollableTable">
                <Table className="table align-middle table-nowrap  w-100 mb-0 table-bordered table-hover  usersTables">
                  <thead className="table-light">
                    <tr>
                      <th>S.N</th>
                      <th className="align-middle"> icon</th>
                      <th className="align-middle"> Image</th>
                      <th className="align-middle"> Name</th>
                      <th className="align-middle"> Title</th>
                      <th className="align-middle"> Sub Title</th>
                      <th className="align-middle"> Mapped Providers</th>
                      <th className="align-middle">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {query ? (
                      <CategoryTable categoryData={results} />
                    ) : (
                      <CategoryTable categoryData={newData} />
                    )}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Category;
