import React, { useEffect, useState } from 'react'
import { Card, Row, Col, CardBody, Table } from 'reactstrap'
import Breadcrumb from "../Common/Breadcrumb"
import ServiceRequestTable from '../Components/DataTables/ServiceRequestTable';
import { toast } from 'react-toastify';

const ServiceRequest = () => {
    const [allrequest, setAllrequest] = useState();
    const [taskStatusData, setTaskStatusData] = useState([]);
    const [pay_statusData, setPay_statusData] = useState([]);
    const [payout_statusData, setPayout_statusData] = useState([]);
    const [refund_statusData, setRefund_statusData] = useState([]);
    const [showresest_Btn, setShowresest_Btn] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [paymentId, setPaymentId] = useState();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');

    useEffect(() => {
        document.title = "WorkDone App | Service Request"
        fetchData();
    }, []);
    const token = localStorage.getItem('authToken');
    const api_url = process.env.REACT_APP_BASE_URL;
    // const api_url = 'http://localhost:3000'
    const fetchData = async () => {
        try {
            const response = await fetch(`${api_url}/servicerequests/getall_request_with_cust`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            })
            const results = await response.json();
            const allData = results.data;
            // console.log(allData, 'allData')
            setAllrequest([...allData].reverse());
        }
        catch (err) {
            console.log(err, 'images fetching error')
        }
    }
    const handle_taskstatus = (e) => {
        const value = e.target.value;
        if (value === 'All') {
            setTaskStatusData(allrequest);
        } else {
            const filtered = allrequest.filter(item => item.status === value);
            console.log(filtered, 'filtered')
            setTaskStatusData(filtered);
            setPay_statusData([]); // Clear other state variables
            setPayout_statusData([]);
            setRefund_statusData([]);
        }
    }
    const handle_payStatus = (e) => {
        const value = e.target.value;
        if (value === 'All') {
            setPay_statusData(allrequest);
        } else {
            const filtered = allrequest.filter(item => item.payment_status === value);
            setPay_statusData(filtered);
            setTaskStatusData([]);
            setPayout_statusData([]);
            setRefund_statusData([]);
        }
    }
    const handle_payOutstatus = (e) => {
        const value = e.target.value;
        if (value === 'All') {
            setPayout_statusData(allrequest);
        } else {
            const filtered = allrequest.filter(item => item.payout_status === value);
            setPayout_statusData(filtered);
            setTaskStatusData([]);
            setPay_statusData([]); // Clear other state variables
            setRefund_statusData([]);
        }
    }
    const handle_refund_status = (e) => {
        const value = e.target.value;
        if (value === 'All') {
            setRefund_statusData(allrequest);
        } else {
            const filtered = allrequest.filter(item => item.refund_status === value);
            setRefund_statusData(filtered);
            setTaskStatusData([]);
            setPay_statusData([]); // Clear other state variables
            setPayout_statusData([]);
        }
    }
    const handleSearch = () => {
        if (!paymentId && !email && !mobile && !startDate && !endDate) {
            toast.error('On what basis do you want to search?')
            return;
        }
        const filteredItems = allrequest.filter(item => {
            const itemDate = new Date(item.createdAt);
            const fromDate = startDate ? new Date(startDate) : null;
            const toDate = endDate ? new Date(endDate) : null;
            const dateInRange = (!fromDate || itemDate >= fromDate) && (!toDate || itemDate <= toDate);
            const paymentIdMatch = !paymentId || item.payment_details?.id.includes(paymentId);
            const emailMatch = !email || (Array.isArray(item.customers) && item.customers.length > 0 && item.customers[0].email && item.customers[0].email.toLowerCase().includes(email.toLowerCase()));
            const mobileMatch = !mobile || item.customers[0]?.mobile.includes(mobile);
            return dateInRange && paymentIdMatch && emailMatch && mobileMatch;
        });
        setSearchResults(filteredItems);
        console.log(filteredItems, 'filteredItems')
        setShowresest_Btn(true);
    }
    const handlereset = () => {
        setSearchResults(allrequest);
        setStartDate('');
        setEndDate('');
        setPaymentId('');
        setEmail('');
        setMobile('');
        setShowresest_Btn(false);

    }
    return (
        <>
            <Breadcrumb title="Dashboard" breadcrumbItem="Service Request" />
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Row className="pb-1 p-3 bg-light">
                                <Col md={12}>
                                    <h4>Serach Service Request</h4>
                                </Col>
                            </Row>
                            <Row className='pt-1 bg-light p-3'>
                                <Col md={4} className=" mt-3">
                                    <div className="mb-3">
                                        <label htmlFor="payment_ID" className="form-label"> Payment ID </label>
                                        <input type="test" className="form-control" value={paymentId} onChange={(e) => setPaymentId(e.target.value)} name="payment_ID" placeholder='Search Payment ID' id="payment_ID" />
                                    </div>
                                </Col>
                                <Col md={4} className=" mt-3">
                                    <div className="mb-3">
                                        <label htmlFor="from_Date" className="form-label"> From Date </label>
                                        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="form-control" name="from_Date" id="from_Date" />
                                    </div>
                                </Col>
                                <Col md={4} className=" mt-3">
                                    <div className="mb-3">
                                        <label htmlFor="to_date" className="form-label"> To Date </label>
                                        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="form-control" name="to_date" id="to_date" />
                                    </div>
                                </Col>
                                <Col md={4} className=" mt-1">
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label"> Email </label>
                                        <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} name="email" placeholder='Search Email' id="email" />
                                    </div>
                                </Col>
                                <Col md={4} className=" mt-1">
                                    <div className="mb-3">
                                        <label htmlFor="mobile_num" className="form-label"> Mobile Number </label>
                                        <input type="number" className="form-control" value={mobile} onChange={(e) => setMobile(e.target.value)} name="mobile_num" placeholder='Search Mobile Number' id="mobile_num" />
                                    </div>
                                </Col>
                                <Col md={4} className=" mt-1">
                                    <div className="mb-3 pt-4">
                                        <button className='btn btn-success' onClick={handleSearch}> <i className='bx bx-search'></i>&nbsp; Find</button> &emsp;
                                        {showresest_Btn === true ?
                                            <button className='btn btn-secondary' onClick={handlereset}> <i className='fa fa-refresh'></i>&nbsp; Reset</button>
                                            : ''}
                                    </div>
                                </Col>
                                {showresest_Btn === true ? <>
                                    <Col md={12}>
                                        {searchResults && searchResults.length > 0 ?
                                            <b>Showing Search Result =  {searchResults.length}</b>
                                            : <b>Showing Search Result =  {searchResults.length}</b>
                                        }
                                    </Col>
                                </> : ''}
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Row className="mt-2 mb-3">
                                <Col md={8}>
                                    <h4>View All Service Request</h4>
                                </Col>
                                <Col md={4} className="text-end">
                                    <div className="">
                                        <p className='text_bold'>Showing All Entries: <span className='text-primary'> {allrequest?.length}</span></p>
                                    </div>
                                </Col>
                            </Row>
                            <div className="table-responsive scrollableTable">
                                <Table className="table align-middle table-nowrap  w-100 mb-0 table-bordered table-hover  usersTables">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="align-middle">S.N</th>
                                            <th className="align-middle"> Name</th>
                                            <th className="align-middle"> Mobile</th>
                                            <th className="align-middle"> Email</th>
                                            <th className="align-middle">Title</th>
                                            <th className="align-middle">Description</th>
                                            <th className="align-middle">Budget (₹)</th>
                                            <th className="align-middle">Location</th>
                                            <th className="align-middle">Images</th>
                                            <th className="align-middle">
                                                <span>
                                                    <select name="status" id="taskStatus" onChange={handle_taskstatus} className='table_select_Css'>
                                                        <option selected disabled>Task Status</option>
                                                        <option value="Open">Open</option>
                                                        <option value="Assigned">Assigned</option>
                                                        <option value="Expired">Expired</option>
                                                        <option value="Completed">Completed</option>
                                                        <option value="All">All</option>
                                                    </select>
                                                </span>
                                            </th>
                                            <th className="align-middle">
                                                <span>
                                                    <select name="payment_status" id="paymentStatus" onChange={handle_payStatus} className='table_select_Css'>
                                                        <option selected disabled>Payment Status</option>
                                                        <option value="Pending">Pending</option>
                                                        <option value="Paid">Paid</option>
                                                        <option value="All">All</option>
                                                    </select>
                                                </span>
                                            </th>
                                            <th className="align-middle">
                                                <span>
                                                    <select name="payout_status" id="payoutStatus" onChange={handle_payOutstatus} className='table_select_Css'>
                                                        <option selected disabled>Payout Status</option>
                                                        <option value="Pending">Pending</option>
                                                        <option value="Released">Released</option>
                                                        <option value="All">All</option>
                                                    </select>
                                                </span>
                                            </th>
                                            <th className="align-middle">
                                                <span>
                                                    <select name="refund_status" id="refundStatus" onChange={handle_refund_status} className='table_select_Css'>
                                                        <option selected disabled>Refund Status</option>
                                                        <option value="Pending">None</option>
                                                        <option value="Paid">Released</option>
                                                        <option value="All">All</option>
                                                    </select>
                                                </span>
                                            </th>
                                            {/* <th className="align-middle">Payment Details</th> */}
                                            <th className="align-middle">Financial Breakup</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {taskStatusData.length > 0 || pay_statusData.length > 0 || payout_statusData.length > 0 || refund_statusData.length > 0 ? (
                                            <ServiceRequestTable
                                                serviceReq_Data={
                                                    taskStatusData.length > 0 ? taskStatusData :
                                                        pay_statusData.length > 0 ? pay_statusData :
                                                            payout_statusData.length > 0 ? payout_statusData :
                                                                refund_statusData.length > 0 ? refund_statusData :
                                                                    allrequest
                                                }
                                            />
                                        ) : searchResults && searchResults.length > 0 ?
                                            <ServiceRequestTable serviceReq_Data={searchResults} />
                                            : allrequest?.length > 0 ? (
                                                <ServiceRequestTable serviceReq_Data={allrequest} />
                                            ) : <tr>
                                                <td colSpan="15" className="react-bs-table-no-data">No data to display.</td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </>
    )
}

export default ServiceRequest
