import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from '../Pages/Login'
import Dashboard from '../Pages/Dashboard/Dashboard'
import PrivateRoute from './index';
import AllCustomers from '../Pages/AllCustomers'
import Category from '../Pages/Category';
import Banner from '../Pages/Banner';
import ChangePassword from '../Pages/ChangePassword';
import ServiceRequest from '../Pages/ServiceRequest';
import Settings from '../Pages/Settings';
import Advertising_competition from '../Pages/Advertising_competition';

const AllRoutes = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path="/dashboard" element={<PrivateRoute Component={Dashboard} />} />
                <Route path="/banner" element={<PrivateRoute Component={Banner} />} />
                <Route path="/category" element={<PrivateRoute Component={Category} />} />
                <Route path="/serviceRequest" element={<PrivateRoute Component={ServiceRequest} />} />
                <Route path="/changePassword" element={<PrivateRoute Component={ChangePassword} />} />
                <Route path='/total_downloads' element={<PrivateRoute Component={AllCustomers} />} />
                <Route path='/advertising_competition' element={<PrivateRoute Component={Advertising_competition} />} />
                <Route path='/settings' element={<PrivateRoute Component={Settings} />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AllRoutes
