import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Card, CardBody } from 'reactstrap'
import OptionCard from '../../Components/HomeContent/Cards/OptionCard'
import Breadcrumb from "../../Common/Breadcrumb"
import WelcomeCard from './WelcomeCard'
import BannerTable from '../../Components/DataTables/BannerTable'

const Dashboard = () => {
  const [newData, setnewData] = useState();
  const [category, setCategory] = useState();
  const [all_customers, setAll_customers] = useState([]);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [allrequest, setAllrequest] = useState();
  const [all_Data, setAll_Data] = useState([]);
  const [allproviders, setPllproviders] = useState([]);

  const token = localStorage.getItem('authToken');
  const api_url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    document.title = "WorkDone App | Dashboard"
    fetchBanners();
    fetchCategory();
    fetch_customers();
    fetch_request();
    fetch_Comp();
  }, []);
  const fetchBanners = async () => {
    try {
      const payload = { is_active: true }
      const response = await fetch(`${api_url}/banners/getall`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      const results = await response.json();
      const dataHere = results.data;
      setnewData([...dataHere].reverse());
    } catch (err) {
      console.log(err, 'images fetching error')
    }
  };
  const fetchCategory = async () => {
    try {
      const payload = { is_active: true }
      const response = await fetch(`${api_url}/servicecategories/getall`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      const results = await response.json();
      const dataHere = results.data;
      setCategory(dataHere.length);
    } catch (err) {
      console.log(err, 'images fetching error')
    }
  };
  const fetch_customers = async () => {
    try {
      const response = await fetch(`${api_url}/customers/getall_customers`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const results = await response.json();
      const dataHere = results.data;
      const providers = dataHere.filter(provider => provider.is_provider);
      setPllproviders(providers.length);
      setAll_customers(dataHere.length);
    } catch (err) {
      console.log(err, 'customers fetching error')
    }
  };
  const handlesearch = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    const filteredResults = newData.filter(item =>
      item.title.toLowerCase().includes(inputValue.toLowerCase())
    );
    setResults(filteredResults);
  };
  const fetch_request = async () => {
    try {
      const response = await fetch(`${api_url}/servicerequests/getall_request_with_cust`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
      const results = await response.json();
      const allData = results.data;
      setAllrequest(allData);
    }
    catch (err) {
      console.log(err, 'images fetching error')
    }
  };
  const fetch_Comp = async () => {
    try {
      const response = await fetch(`${api_url}/interestdata/getall`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const results = await response.json();
      const dataHere = results.data;
      setAll_Data(dataHere);
    } catch (err) {
      console.log(err, 'customers fetching error')
    }
  };
  return (
    <>

      <Breadcrumb breadcrumbItem="Dashboard" title="Dashboard" />
      <Row>
        <WelcomeCard />
        <Col xl={8}>
          <Row>
            <OptionCard bgcolor="bgcolorone" title=" Banners" counts={newData?.length} linkhere="/banner" iconClass="bx-image" />
            <OptionCard bgcolor="bgcolortwo" title=" Category" counts={category} linkhere="/category" iconClass="bx-copy-alt" />
            <OptionCard bgcolor="bgcolothree" title=" Total Downloads" counts={all_customers} linkhere="/total_downloads" iconClass="bx-user" />
            <OptionCard bgcolor="bgcolorfour" title="Pending Request" counts={allrequest?.length} linkhere="/serviceRequest" iconClass="bx-analyse" />
            <OptionCard bgcolor="bgcolorone" addcss="removee_link" title=" Providers" counts={allproviders} linkhere="/dashboard" iconClass="bx-user-circle" />
            {/* <OptionCard bgcolor="bgcolorone" title=" Competition" counts={all_Data?.length} linkhere="/Advertising_competition" iconClass="bx-award" /> */}
            <OptionCard bgcolor="bgcolortwo" title=" Settings" counts="1" linkhere="/settings" iconClass="bx-cog" />

          </Row>
        </Col>
      </Row>
      <br /><br />
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row className="mt-2">
                <Col md={8}>
                  <h4>View All Banners</h4>
                </Col>
                <Col md={4} className="text-end">
                  <div className="searching_optipn text-end">
                    <Row>
                      <Col md={4} className="mt-1 text-left">
                        <label htmlFor="searchiong">Search:</label>
                      </Col>
                      <Col md={8}>
                        <input type="text" value={query} onChange={handlesearch} placeholder="Search Here" className="form-control" />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <hr />
              <div className="table-responsive scrollableTable">
                <Table className="table align-middle table-nowrap  w-100 mb-0 table-bordered table-hover  usersTables">
                  <thead className="table-light">
                    <tr>
                      <th>S.N</th>
                      <th className="align-middle">Title</th>
                      <th className="align-middle">Image</th>
                      <th className="align-middle">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {query ? <BannerTable alldata={results} /> : <BannerTable alldata={newData} />}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Dashboard
